var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pt-2" },
    [
      !_vm.payload.hideHeader
        ? [
            _c(
              "v-card-title",
              { staticClass: "pa-0 pl-2 pr-2" },
              [
                _vm.payload.hasPagination
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          "x-small": "",
                          fab: "",
                          disabled: _vm.payload.pagination.page === 1,
                        },
                        on: { click: _vm.previousList },
                      },
                      [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                      1
                    )
                  : _vm._e(),
                _vm.payload.addClickHandler && _vm.payload.hasPermission
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: {
                          color: "primary",
                          fab: "",
                          "x-small": "",
                          id: "newbtn",
                        },
                        on: { click: _vm.payload.addClickHandler },
                      },
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    )
                  : _vm._e(),
                _vm.payload.isTickets && _vm.payload.hasPermission
                  ? _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "ml-3",
                                        attrs: {
                                          color: "primary",
                                          fab: "",
                                          "x-small": "",
                                          id: "ticket_actions",
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-dots-horizontal"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          486761983
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { dense: "" } },
                          _vm._l(_vm.menuList, function (item, index) {
                            return _c(
                              "v-list-item",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.payload.menuActions(item.value)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", {
                                      attrs: { medium: "" },
                                      domProps: {
                                        textContent: _vm._s(item.icon),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.payload.isCustomModule &&
                _vm.payload.relateClickHandler &&
                _vm.payload.hasPermission
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: {
                          color: "primary",
                          fab: "",
                          "x-small": "",
                          id: "relatebtn",
                        },
                        on: { click: _vm.payload.relateClickHandler },
                      },
                      [_c("v-icon", [_vm._v("mdi-swap-horizontal")])],
                      1
                    )
                  : _vm._e(),
                _c("v-spacer"),
                _c("h5", [
                  _c("span"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(_vm.payload.moduleTitle)) +
                      " " +
                      _vm._s(_vm.$t("message.checklist.title"))
                  ),
                ]),
                _c("v-spacer"),
                _c("span", { staticClass: "mx-3 subtitle-2" }, [
                  _vm._v(
                    _vm._s(_vm.payload.list.length) +
                      " " +
                      _vm._s(_vm.$t("message.common.of")) +
                      " " +
                      _vm._s(_vm.payload.total)
                  ),
                ]),
                _vm.payload.hasFilter
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          fab: "",
                          dark: "",
                          "x-small": "",
                          color: "primary",
                        },
                        on: { click: _vm.toggleFilter },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-filter"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.payload.refreshHandler
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          fab: "",
                          dark: "",
                          "x-small": "",
                          color: "primary",
                        },
                        on: {
                          click: function ($event) {
                            ;(_vm.payload.list = []),
                              (_vm.payload.total = 0),
                              (_vm.payload.startPage = 0),
                              _vm.payload.refreshHandler()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-reload"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.payload.hasPagination
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          "x-small": "",
                          fab: "",
                          disabled: _vm.hasNoNext || _vm.payload.hasNoNext,
                        },
                        on: { click: _vm.nextList },
                      },
                      [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.payload.hasSearch
              ? _c(
                  "v-card-title",
                  { staticClass: "px-3 pt-2 py-0" },
                  [
                    _c("v-text-field", {
                      staticClass: "pa-0",
                      attrs: {
                        outlined: "",
                        label: _vm.$t("message.common.search"),
                        "hide-details": "",
                        dense: "",
                      },
                      model: {
                        value: _vm.searchTerm,
                        callback: function ($$v) {
                          _vm.searchTerm = $$v
                        },
                        expression: "searchTerm",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c("v-virtual-scroll", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.payload.list.length,
            expression: "payload.list.length",
          },
        ],
        attrs: {
          items: _vm.payload.list,
          "item-height": "85",
          "max-height": "65vh",
          id: _vm.id,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-card-text",
                  { staticClass: "pa-1 pt-0" },
                  [
                    _vm.payload.isTickets
                      ? [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { multiple: "" },
                                  model: {
                                    value: _vm.selectedTickets,
                                    callback: function ($$v) {
                                      _vm.selectedTickets = $$v
                                    },
                                    expression: "selectedTickets",
                                  },
                                },
                                [
                                  [
                                    _c("v-list-item", {
                                      key: _vm.index + "_record",
                                      staticClass: "custom_list_style",
                                      attrs: { value: _vm.record },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var active = ref.active
                                              return [
                                                _c(
                                                  "v-list-item-avatar",
                                                  {
                                                    staticClass:
                                                      "avatar_custom_margin",
                                                    attrs: { size: "20" },
                                                  },
                                                  [
                                                    _c("v-icon", {
                                                      attrs: {
                                                        size: "20",
                                                        color: "primary",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.payload.moduleIcon
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "pa-1" },
                                                  _vm._l(
                                                    _vm.payload.items,
                                                    function (record, index) {
                                                      return _c(
                                                        "p",
                                                        {
                                                          key: index,
                                                          staticClass: "mb-0",
                                                          class:
                                                            index === 0
                                                              ? "subtitle-2 font-weight-medium"
                                                              : "font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[record.value]
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "v-list-item-action",
                                                  {
                                                    staticClass:
                                                      "list_action_custom_margin",
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        staticClass: "mx-0",
                                                        attrs: {
                                                          row: "",
                                                          wrap: "",
                                                        },
                                                      },
                                                      [
                                                        _vm.payload
                                                          .viewClickHandler
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color:
                                                                    "primary lighten-1",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.payload.viewClickHandler(
                                                                        _vm.record
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-eye"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !active
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "grey lighten-1",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-checkbox-multiple-blank-circle-outline"
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-3",
                                                                attrs: {
                                                                  color: "info",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-checkbox-multiple-blank-circle"
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm.payload.list.length > 0
                      ? [
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-1 mt-1",
                                attrs: { outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.payload.editHandler(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "pa-1 pt-0 pb-0",
                                    style: {
                                      "background-color": "#e8eaf6",
                                      "min-height": "75px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1 pb-0",
                                            attrs: {
                                              cols: "7",
                                              sm: "8",
                                              md: "9",
                                              lg: "9",
                                              xs: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "mt-n1" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    attrs: { cols: "11" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return _vm._l(
                                                                  _vm.payload
                                                                    .items,
                                                                  function (
                                                                    record,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "p",
                                                                      _vm._g(
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "mb-0",
                                                                          class:
                                                                            index ===
                                                                            0
                                                                              ? "subtitle-2 font-weight-medium mt-1"
                                                                              : "font-weight-regular",
                                                                          style:
                                                                            {
                                                                              "white-space":
                                                                                index ===
                                                                                0
                                                                                  ? "nowrap"
                                                                                  : "normal",
                                                                              overflow:
                                                                                index ===
                                                                                0
                                                                                  ? "hidden"
                                                                                  : "visible",
                                                                              "text-overflow":
                                                                                "ellipsis",
                                                                            },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item[
                                                                                record
                                                                                  .value
                                                                              ]
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      _vm._l(
                                                        _vm.payload.items,
                                                        function (
                                                          record,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "span",
                                                            { key: index },
                                                            [
                                                              index === 0
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            record
                                                                              .value
                                                                          ]
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1",
                                            class:
                                              _vm.$vuetify.breakpoint.xs ||
                                              _vm.$vuetify.breakpoint.xs
                                                ? ""
                                                : " text-right",
                                            attrs: {
                                              cols: "5",
                                              sm: "4",
                                              md: "3",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _vm.payload.copyChecklist
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                      id: "copybtn",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.payload.copyChecklist(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-content-copy"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.payload.isCustomModule
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                      id: "unrelatebtn",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.payload.unrelateClickHandler(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-repeat-off"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.payload.isChecklist &&
                                            item.can_send_email == 1 &&
                                            item.checklist_recipients != null
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.payload.sendChecklistReport(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-email"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.payload.isChecklist
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "warning",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.payload.changeStatus(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.is_completed
                                                            ? "mdi-reload"
                                                            : "mdi-check"
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.payload.isChecklist &&
                                            item.has_template &&
                                            item.is_file_uploaded
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.payload.pdfTemplateReportDownload(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-file-pdf"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.payload.downloadHandler(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-download"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                            _vm.payload.hasPermission &&
                                            !_vm.payload.isChecklist
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.deleteRecord(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                        },
                                                      },
                                                      [_vm._v("mdi-delete")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                        ]
                      : _vm._e(),
                    !_vm.payload.loading && _vm.payload.list.length === 0
                      ? _c(
                          "v-alert",
                          {
                            staticClass: "mt-2",
                            attrs: { prominent: "", text: "", type: "info" },
                          },
                          [
                            _c(
                              "v-row",
                              { attrs: { align: "center" } },
                              [
                                _c("v-col", { staticClass: "grow" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.common.noResults"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c("v-card-text", { staticClass: "pa-1 mb-2" }, [
        _vm.payload.loading
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-linear", {
                  attrs: {
                    active: _vm.payload.loading,
                    indeterminate: _vm.payload.loading,
                    color: "deep-purple accent-4",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }