var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      ["project", "equipment", "chemicallist"].includes(_vm.module)
        ? _c(
            "v-card",
            { staticClass: "pa-1", attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-1 pt-2",
                          attrs: { cols: "12", lg: "8", sm: "12", md: "8" },
                        },
                        [
                          _vm.module === "project"
                            ? _c(
                                "h3",
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.projects.project")) +
                                      ": "
                                  ),
                                  _vm.propId
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/projects/" +
                                              _vm.$route.params[_vm.propId],
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.printProjectName))]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/projects/" +
                                              _vm.globalListSelectedProjectId,
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.printProjectName))]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.module === "equipment"
                            ? _c("h3", [_vm._v(_vm._s(_vm.printEquipmentName))])
                            : _vm._e(),
                          _vm.module === "chemicallist"
                            ? _c("h3", [
                                _vm._v(_vm._s(_vm.printChemicallistName)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-1",
                          attrs: { cols: "12", lg: "4", md: "4", sm: "12" },
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              filled: "",
                              items: _vm.moduleList,
                              dense: "",
                              "hide-details": "",
                              clearable: "",
                              label: "ModuleName",
                              "item-text": "name",
                              "item-value": "id",
                            },
                            model: {
                              value: _vm.moduleId,
                              callback: function ($$v) {
                                _vm.moduleId = $$v
                              },
                              expression: "moduleId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mx-0 mb-1" },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-1 px-1",
              attrs: { cols: "12", lg: "8", sm: "12", md: "8" },
            },
            [
              _c("v-autocomplete", {
                staticClass: "test-class",
                attrs: {
                  chips: "",
                  color: "blue-grey lighten-2",
                  disabled: _vm.checklistTemplatesLoading,
                  loading: _vm.checklistTemplatesLoading,
                  items: _vm.getSelectedChecklists,
                  "item-text": "title",
                  "item-value": "id",
                  filled: "",
                  label: _vm.$t("message.layout.checklists"),
                  dense: "",
                  "hide-details": "",
                },
                on: { change: _vm.goToChecklistForm },
                model: {
                  value: _vm.getChecklist,
                  callback: function ($$v) {
                    _vm.getChecklist = $$v
                  },
                  expression: "getChecklist",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-1 px-1",
              attrs: { cols: "12", lg: "4", md: "4", sm: "12" },
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.checklistStatusList,
                  "item-text": function (val) {
                    return _vm.$t(val.text)
                  },
                  "item-value": "value",
                  filled: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.$t("message.projects.status"),
                },
                model: {
                  value: _vm.checklistStatus,
                  callback: function ($$v) {
                    _vm.checklistStatus = $$v
                  },
                  expression: "checklistStatus",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.compactListPayload.list.length
        ? _c("compact-list", {
            attrs: { payload: _vm.compactListPayload, id: "globalChecklist" },
          })
        : [
            _c(
              "v-card",
              { staticClass: "mt-2 pa-0", attrs: { elevation: "0" } },
              [
                _c(
                  "v-card-text",
                  { staticClass: "text-h6 text-center font-weight-bold" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("message.common.no_records")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.statusChangeDialog,
            callback: function ($$v) {
              _vm.statusChangeDialog = $$v
            },
            expression: "statusChangeDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("message.common.confirmStatusChange")) +
                    " "
                ),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-5" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("message.common.areYouSureToChangeStatus", {
                        statusText:
                          _vm.activeItemForStatusChange &&
                          _vm.activeItemForStatusChange.is_completed
                            ? _vm.$t("message.common.incomplete")
                            : _vm.$t("message.common.complete"),
                      })
                    ) +
                    " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        id: "statusChangeConfirm",
                        loading: _vm.statusChangeLoading,
                      },
                      on: { click: _vm.confirmChangeStatus },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "warning", id: "statusChangeClose" },
                      on: {
                        click: function ($event) {
                          ;(_vm.statusChangeDialog = false),
                            (_vm.activeItemForStatusChange = null)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "650", persistent: "" },
          model: {
            value: _vm.importCsvDialog,
            callback: function ($$v) {
              _vm.importCsvDialog = $$v
            },
            expression: "importCsvDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(_vm._s(_vm.$t("message.checklist.importCSV")) + " "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "mt-4" },
                [
                  _c("span", {
                    staticClass: "h4 font-weight-bold",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("message.checklist.CSVFileInfo")
                      ),
                    },
                  }),
                  _vm._l(_vm.csvInformations, function (item, index) {
                    return _c("ul", { key: index }, [
                      _c("li", { staticClass: "mt-1" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                    ])
                  }),
                  _c("v-divider", { staticClass: "my-3" }),
                  _c("v-file-input", {
                    attrs: {
                      "prepend-icon": "",
                      "hide-details": "",
                      "prepend-inner-icon": "mdi-file-delimited",
                      label: _vm.$t("message.checklist.addCSVFile"),
                      accept: ".csv",
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.csvFile,
                      callback: function ($$v) {
                        _vm.csvFile = $$v
                      },
                      expression: "csvFile",
                    },
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.readCSVFileAndSetData()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.import")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.withoutImport()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.checklist.withoutImport")))]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text-custom",
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          ;(_vm.importCsvDialog = false),
                            (_vm.csvFile = null),
                            (_vm.getChecklist = 0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.copyDialog,
            callback: function ($$v) {
              _vm.copyDialog = $$v
            },
            expression: "copyDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "pa-5" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.checklist.copy_confirm")) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        id: "copyConfirm",
                        loading: _vm.copyLoading,
                      },
                      on: { click: _vm.copyChecklistConfirm },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "warning", id: "copyClose" },
                      on: {
                        click: function ($event) {
                          ;(_vm.copyDialog = false),
                            (_vm.activeItemForcopy = null)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }